import React from "react"
import { FaCcPaypal } from "react-icons/fa"
import footerStyles from "../styles/footer.module.scss"

export const Footer = () => {
  return (
    <footer>
      <div
        className="footer has-background-primary"
        style={{ borderTop: "15px solid #fee3ae" }}
      >
        <div className="container">
          <div className={footerStyles.wrapper}>
            <p>
              © {new Date().getFullYear()} Sydney Reiter | Website by: Brandon
              Veth
            </p>
            <div style={{ maxWidth: "500px" }}>
              <p className="level">
                Pay online:
                <a
                  className="is-medium button is-link is-outlined"
                  style={{ textDecoration: "none", marginBottom: "1rem" }}
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=NWUNX6LDW2TFA"
                >
                  <span className="icon">
                    <FaCcPaypal />
                  </span>
                  <span>PayPal</span>
                </a>
              </p>
              <p>
                Sydney Reiter LMSW, ACSW <br />
                999 Haynes <br />
                Suite 335 <br />
                Birmingham, Michigan 48009
              </p>
              <p>
                Phone: <a href="tel:1-248-408-3058">248-408-3058</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
