import React, { useState } from "react"
import { Link } from "gatsby"
import { AiOutlineDown } from "react-icons/ai"
import navStyles from "../styles/navbar.module.scss"

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <nav className="navbar is-dark">
      <div className="container">
        <div className="navbar-brand">
          <Link
            className="navbar-item is-size-2-tablet is-size-3-tablet is-size-4 has-text-white"
            to="/"
            style={{ flexDirection: "column" }}
          >
            Sydney Reiter
            <span
              className="is-size-6-desktop  is-size-7 has-text-white has-text-link"
              style={{ marginTop: "-8px" }}
            >
              Counseling & Psychotherapy
            </span>
            {/* <p
              className="is-size-2-tablet has-text-white"
              style={{ paddingBottom: 0 }}
            >
              Sydney Reiter
            </p>
            <p className="is-size-6-tablet is-size-7 has-text-white has-text-link">
              Counseling & Psychotherapy
            </p> */}
          </Link>
          <a
            role="button"
            href="#foo"
            className={`navbar-burger ${menuOpen ? "is-active" : ""}  is-large`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${menuOpen ? "is-active" : ""}`}>
          <div className="navbar-end ">
            <Link
              to="/my-approach"
              className={`navbar-item is-size-5-desktop is-size-6 ${navStyles.navbarItem}`}
              activeStyle={{ textDecoration: "underline" }}
            >
              My Approach
            </Link>
            <Link
              to="/about"
              className={`navbar-item is-size-5-desktop is-size-6 ${navStyles.navbarItem}`}
              activeStyle={{ textDecoration: "underline" }}
            >
              About
            </Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <Link
                to="/services"
                className={`navbar-item is-size-5-desktop is-size-6 is-arrowless ${navStyles.navbarItem}`}
                activeStyle={{ textDecoration: "underline" }}
              >
                Services{" "}
                <AiOutlineDown
                  className="is-hidden-touch"
                  style={{ paddingLeft: "0.3rem" }}
                />
              </Link>
              <div className="navbar-dropdown is-size-5-desktop is-size-6 has-text-grey-lighter">
                <Link to="/service/individual-therapy" className="navbar-item">
                  Individual Therapy
                </Link>
                <Link
                  to="/service/relationship-marriage-therapy"
                  className="navbar-item"
                >
                  Relationship/Marriage Therapy
                </Link>
                {/* <Link to="/family-therapy" className="navbar-item">
                  Family Therapy
                </Link> */}
                <Link to="/service/group-therapy" className="navbar-item">
                  Group Therapy
                </Link>
                <Link
                  to="/service/spirituality-workshops"
                  className="navbar-item"
                >
                  Spirituality Workshops
                </Link>
              </div>
            </div>
            <Link
              to="/common-problems"
              className={`navbar-item is-size-5-desktop is-size-6 ${navStyles.navbarItem}`}
              activeStyle={{ textDecoration: "underline" }}
            >
              Common Problems
            </Link>
            <Link
              to="/counseling-faq"
              className={`navbar-item is-size-5-desktop is-size-6 ${navStyles.navbarItem}`}
              activeStyle={{ textDecoration: "underline" }}
            >
              FAQ
            </Link>
            {/* <div className="navbar-item has-dropdown is-hoverable">
              <Link
                to="/"
                className={`navbar-item is-size-5 is-arrowless ${navStyles.navbarItem}`}
              >
                Info{" "}
                <AiOutlineDown
                  className="is-hidden-touch"
                  style={{ paddingLeft: "0.3rem" }}
                />
              </Link>
              <div className="navbar-dropdown is-size-5 ">
                <Link to="/counseling-faq" className="navbar-item">
                  Counseling FAQ
                </Link>
                <Link to="/" className="navbar-item">
                  Fees
                </Link>
              </div>
            </div> */}
            <Link
              to="/contact"
              className={`navbar-item is-size-5-desktop is-size-6 ${navStyles.navbarItem}`}
              activeStyle={{ textDecoration: "underline" }}
            >
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  )
}
export default Navbar
