import React from "react"
import "../styles/styles.scss"
import NavBar from "../components/navbar"
// import { ContactBar } from "./contactBar"
import { Footer } from "./footer"

export default ({ children }) => (
  <div>
    {/* <ContactBar /> */}
    <NavBar />
    <div>{children}</div>
    <Footer />
  </div>
)
