import React from "react"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <div
        style={{ minHeight: "80vh", paddingTop: "3rem" }}
        className="has-text-centered"
      >
        <h1 className="is-size-2">Page Not found</h1>
        <p>
          You just hit a route that doesn&#39;t exist... click a link above.
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
